(function () {
    $(".form-select").each(function () {
        const $this = $(this);
        const placeholder = $(this).attr('data-placeholder')
        console.log(placeholder);
        $this.select2({
            placeholder: placeholder,
            containerCssClass: 'select-rounded',
            dropdownCssClass:  'select-rounded-dropdown',
        });
    });
})()