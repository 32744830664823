(function () {
    if (!$('#modal-carousel')) {
        return;
    }

    let $modal = $('#modal-carousel')
    let $bigSlides = $('#modal-carousel .carousel-item')
    let $smallSlides = $('#modal-carousel .list-inline-item')
    let $btnImg = $('#gallery-links a')

    $btnImg.on('click', function () {
        let imgNumber = $(this).attr('data-active')
        $bigSlides.removeClass('active')
        $modal.find('[data-slide=' + imgNumber + ']').addClass('active')
        $smallSlides.removeClass('active')
        $modal.find('[data-slide-to=' + imgNumber + ']').parent().addClass('active')
    })

    $("#modal-carousel").carousel({
        interval: false
    });
})()