(function () {
    let $buttons = $('.search-result__grid-nav button');
    let $list = $('#list-result');
    let $grid = $('#grid-result');
    let $block = $('.search-results')

    $(window).on('load resize', function () {
        if (!$buttons) {
            return;
        }
        if ($(window).width() <= 991) {
            $buttons.removeClass('active')
            $grid.addClass('active')
            $block.addClass('search-results--grid')
        }

    });

    $list.on('click', function () {
        $buttons.removeClass('active')
        $(this).addClass('active')
        $block.removeClass('search-results--grid')
    })
    $grid.on('click', function () {
        $buttons.removeClass('active')
        $(this).addClass('active')
        $block.addClass('search-results--grid')
    })

})()